<template>
<div class="wallet-container">
    <div class="walletList">
        <div>
            <div class="tr-body">
                <div class="name-container">
                    <img class="list-img" :src="'/coins/'+ 'TOMAN'+ '.png'">
                    <span>
                        تومان
                    </span>
                </div>
                <div class="balance">
                    <!-- {{$toLocal(wallet[1].totalBalance,$decimal[wallet[0]])}} {{$coins[wallet[0]].code}} -->
                    {{$toLocal(65465454654)}} <span class="ENname">(تومان)</span>
                </div>
                <div class="balance">
                    <!-- {{$toLocal(wallet[1].totalBalance,$decimal[wallet[0]])}} {{$coins[wallet[0]].code}} -->
                    <!-- {{$toLocal(65465454654)}} <span class="ENname">(تومان)</span> -->
                </div>
                <!-- <div class="deal">
                    {{walletList.deal.toLocaleString()}}
                    {{$toLocal(wallet[1].blockedCredit,$decimal[wallet[0]])}}
                    {{$toLocal(12121212121)}}
                </div>
                <div class="withdrawal">
                    {{walletList.withdrawal.toLocaleString()}}
                    {{$toLocal(wallet[1].credit,$decimal[wallet[0]])}}
                    {{$toLocal(12121212121)}}
                </div> -->
                <div>
                    <span class="opration" @click="$router.push('rial-deposit')">
                        واریز
                    </span>
                </div>
                <div>
                    <span class="opration" @click="$router.push('rial-withdrawal')">
                        برداشت
                    </span>
                </div>
                <div>
                    <span class="opration" @click="$router.push('withdrawal-coin')">
                        <!-- برداشت -->
                    </span>
                </div>
            </div>
            <!-- <div class="tr-head">
                <div v-for="(item , index) in heade" :key=index :class="item.class">
                    {{item.title}}
                </div>
            </div> -->
            <div class="tr-body" v-for="(wallet , index) in wallets.filter(e => e[0]!=='TOMAN')" :key="index">
                <div class="name-container">
                    <img class="list-img" :src="'/coins/'+ 'BITCOIN'+ '.png'">
                    <span>
                        {{$coinLabel['BITCOIN']}}
                    </span>
                    <span class="ENname">
                        <!-- {{$coinLabel['BITCOIN']}} -->
                        (BTC)
                    </span>
                </div>
                <div class="balance">
                    <!-- {{$toLocal(wallet[1].totalBalance,$decimal[wallet[0]])}} {{$coins[wallet[0]].code}} -->
                    {{$toLocal(0.01)}} <span class="ENname">(BTC)</span>
                </div>
                <div class="value">
                    <!-- {{walletList.value.toLocaleString()}} -->
                    <!-- {{$toLocal(wallet[1].wholePriceOfCredit,0)}} -->
                    {{$toLocal(12121212121)}} <span class="ENname">(تومان)</span>
                </div>
                <!-- <div class="deal">
                    {{walletList.deal.toLocaleString()}}
                    {{$toLocal(wallet[1].blockedCredit,$decimal[wallet[0]])}}
                    {{$toLocal(12121212121)}}
                </div>
                <div class="withdrawal">
                    {{walletList.withdrawal.toLocaleString()}}
                    {{$toLocal(wallet[1].credit,$decimal[wallet[0]])}}
                    {{$toLocal(12121212121)}}
                </div> -->
                <div>
                    <span class="opration" @click="$router.push('deposit-coin')">
                        واریز
                    </span>
                </div>
                <div>
                    <span class="opration" @click="$router.push('withdrawal-coin')">
                        برداشت
                    </span>
                </div>
                <div>
                    <a @click="$router.push('/advancetrade/TOMAN/BITCOIN')">
                        <span class="opration">
                            معامله
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="left-side">
        <div class="chart-container">
                <apexchart class="apex" type="donut" width="100%" height="100%" :options="chartOptions" :series="series" />
                <div @click="apexToman=false" :class="{'priceSelect':apexToman}" class="pricetype tetherp">
                    <img src="/coins/TETHER.png" alt="">
                </div>
                <div @click="apexToman=true" :class="{'priceSelect':!apexToman}" class="pricetype tomanp">
                    <img src="/coins/TOMAN.png" alt="">
                </div>
        </div>
        <div class="btn-container">
            <button class="deposit" @click="$router.push('rial-deposit')">
                واریز
            </button>
            <button class="withdraw" @click="$router.push('rial-withdrawal')">
                برداشت
            </button>
        </div>
        <div class="btn-container">
            <span class="wealth-title" :class="{'active-title' : sum}" @click="sum = true">
                پراکندگی دارایی
            </span>
            <span class="wealth-title" :class="{'active-title': !sum}" @click="sum = false">
                تجمیع دارایی
            </span>
        </div>
        <div class="wealth-coin">
            <img class="list-img" :src="'/coins/'+ 'BITCOIN'+ '.png'">
            <span>
                بیت کوین
            </span>
            <span>
                65%
            </span>
        </div>
        <div class="wealth-coin">
            <img class="list-img" :src="'/coins/'+ 'BITCOIN'+ '.png'">
            <span>
                بیت کوین
            </span>
            <span>
                65%
            </span>
        </div>
        <div class="wealth-coin">
            <img class="list-img" :src="'/coins/'+ 'BITCOIN'+ '.png'">
            <span>
                بیت کوین
            </span>
            <span>
                65%
            </span>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'Wallet',
    data() {
        return {
            sum: true,
            apexToman:false,
            series: [
                57465764, 45764765
            ],
            chartOptions: {
                chart: {
                    width: '50px',
                    fontFamily: 'shabnam',
                },

                // responsive: [{
                //     breakpoint: 400,
                //     options: {
                //         chart: {
                //             width: '150px',
                //         },
                //     },
                // }],

                colors: [
                    '#3f51b5', '#03a9f4', '#4caf50', '#f9ce1d', '#FF9800', '#00E396', '#FF4560', '#775DD0', '#5C4742', '#2b908f', '#A300D6', '#C5D86D'
                ],
                theme: {
                    mode: 'light',
                    palette: 'palette2'
                },
                stroke: {
                    width: 1,
                    colors: undefined
                },
                labels: [],
                dataLabels: {
                    style: {
                        fontFamily: 'shabnam'
                    }
                },
                legend: {
                    show: false,
                },
                plotOptions: {
                    pie: {
                        offsetX: 0,
                        offsetY: 0,
                        donut: {
                            size: '65%',
                            background: 'white',
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'shabnam',
                                    fontWeight: 600,
                                    color: 'var(--tooDark)',
                                    offsetY: -17,
                                    formatter: function (val) {
                                        return val.name || val
                                    }
                                },
                                value: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'shabnam',
                                    fontWeight: 400,
                                    color: '#3D49CC',
                                    offsetY: 10,
                                    formatter: function (val) {
                                        return parseFloat(val).toLocaleString()
                                    }
                                },
                                total: {
                                    show: true,
                                    showAlways: false,
                                    label: 'ارزش کل دارایی',
                                    fontSize: '12px',
                                    fontFamily: 'shabnam',
                                    fontWeight: 600,
                                    color: 'black',
                                    formatter: function (w) {
                                        w = w.globals.seriesTotals.reduce((a, b) => a + b)
                                        return w.toLocaleString() + '\n' + 'تومان'
                                    }
                                }
                            }
                        },
                    }
                },
                tooltip: {
                    enabled: true,
                    fillSeriesColor: true,
                    custom: function ({
                        seriesIndex,
                        w
                    }) {
                        return ` ${w.globals.seriesNames[seriesIndex].value.toLocaleString()} ${w.globals.seriesNames[seriesIndex].name} `
                    }
                }
            },
            wallets: [{
                    'TOMAN': {
                        blockedCredit: 0,
                        credit: 0,
                        totalBalance: 0,
                        wholePriceOfCredit: 0
                    }
                },
                {
                    'BITCOIN': {
                        blockedCredit: 0,
                        credit: 0,
                        totalBalance: 0,
                        wholePriceOfCredit: 0
                    },
                },
                {
                    'BITCOIN_CASH': {
                        blockedCredit: 0,
                        credit: 0,
                        totalBalance: 0,
                        wholePriceOfCredit: 0
                    },
                },
                {
                    'ETHEREUM': {
                        blockedCredit: 0,
                        credit: 0,
                        totalBalance: 0,
                        wholePriceOfCredit: 0
                    },
                },
                {
                    'TRON': {
                        blockedCredit: 0,
                        credit: 0,
                        totalBalance: 0,
                        wholePriceOfCredit: 0
                    },
                },
                {
                    'TETHER': {
                        blockedCredit: 0,
                        credit: 0,
                        totalBalance: 0,
                        wholePriceOfCredit: 0
                    }
                },
                {
                    'TETHER': {
                        blockedCredit: 0,
                        credit: 0,
                        totalBalance: 0,
                        wholePriceOfCredit: 0
                    }
                },
                {
                    'TETHER': {
                        blockedCredit: 0,
                        credit: 0,
                        totalBalance: 0,
                        wholePriceOfCredit: 0
                    }
                },
                {
                    'TETHER': {
                        blockedCredit: 0,
                        credit: 0,
                        totalBalance: 0,
                        wholePriceOfCredit: 0
                    }
                },
                {
                    'TETHER': {
                        blockedCredit: 0,
                        credit: 0,
                        totalBalance: 0,
                        wholePriceOfCredit: 0
                    }
                },
                {
                    'TETHER': {
                        blockedCredit: 0,
                        credit: 0,
                        totalBalance: 0,
                        wholePriceOfCredit: 0
                    }
                },
                {
                    'TETHER': {
                        blockedCredit: 0,
                        credit: 0,
                        totalBalance: 0,
                        wholePriceOfCredit: 0
                    }
                },
                {
                    'TETHER': {
                        blockedCredit: 0,
                        credit: 0,
                        totalBalance: 0,
                        wholePriceOfCredit: 0
                    }
                }
            ],
            heade: [{
                    title: "ارز",
                    class: ""
                },
                {
                    title: "موجودی",
                    class: "balance"
                },
                {
                    title: "ارزش",
                    class: "value"
                },
                // {
                //     title: "در معامله",
                //     class: "deal"
                // },
                // {
                //     title: "قابل برداشت",
                //     class: "withdrawal"
                // },
                {
                    title: "عملیات",
                    class: ""
                }
            ]
        }
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.priceSelect{
    img{
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);

    }
}
.tetherp{
    right: 0;
}
.tomanp{
    left: 0;
}
.pricetype{
    position: absolute;
    bottom: 10px;
    background: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img{
        width: 80%;
        height: 80%;
    }
}
.apex{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-top: 20px;
}
.wallet-container {
    display: flex;
    justify-content: space-between;
}

.wallet {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    /* max-width: 995px; */
    width: 100%;
}

.wallet-title {
    height: 68px;
    display: flex;
    box-sizing: border-box;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    // background: white;
    width: 100%;
    flex-wrap: wrap;
    column-gap: 20px;
}

.wallet-title-p {
    /* font-size: 22px; */
    font-size: clamp(16px, 1vw, 22px);
    margin: 0;
}

.wallet-title-number {
    /* font-size: 22px; */
    font-size: clamp(16px, 1vw, 22px);
    color: #3D49CC;
    margin: 0;
}

.wallet-info {
    // background: #FFFFFF;
    border-radius: 3px;
    min-height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 10px 20px;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
}

.info-img {
    width: 50px;
    height: 50px;
}

.numbers-container {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;
    flex-grow: 1;
}

.numbers-container p {
    margin: 0;
}

.first-info {
    display: flex;
    align-items: center;
    column-gap: 10px;
    flex-grow: 1;
}

.number {
    font-size: 18px;
    color: #3D49CC;
}

.number-title {
    font-size: 14px;
    color: rgba(64, 64, 64, 0.5)
}

.info-btn {
    color: rgba(61, 73, 204, 0.5);
    border: none;
    width: 98px;
    height: 44px;
    background: transparent;
    box-sizing: border-box;
    border-radius: 3px;
}

.info-btn:hover {
    border: 1px solid #3D49CC;
    color: rgba(61, 73, 204, 1);
}

.btns {
    display: flex;
    column-gap: 10px;
    flex-grow: 1;
    justify-content: flex-end;
}

.walletList {
    // background: #FFFFFF;
    border-radius: 3px;
    // height: 471px;
    box-sizing: border-box;
    padding: 10px 20px;
    flex-grow: 1;
    /* overflow: scroll; */
}

table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

.tr-head {
    height: 50px;
    display: flex;
    justify-content: space-between;
}

.tr-head td {
    color: #999999;
    font-size: 12px;
}

.tr-head td:first-of-type {
    text-align: right;
    padding-right: 10px;
}

.tr-body {
    font-size: 14px;
    background: #FFFFFF;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    padding: 0 10px;
    height: 64px;
    align-items: center;
    column-gap: 20px;
}

.list-img {
    width: 30px;
    height: 30px;
}

.tr-body td:first-of-type {
    display: flex;
    align-items: center;
    column-gap: 10px;
    height: 42px;
}

.opration {
    font-size: 12px;
    color: #888E95;
    cursor: pointer;
    width: auto;
}

.opration:hover {
    color: rgba(61, 73, 204, 1);
    border-bottom: 1px solid rgba(61, 73, 204, 1);
}

.ENname {
    color: #888E95;
}

.name-container {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.left-side {
    width: 100%;
    max-width: 338px;
    // height: 1029px;
    margin: 0 auto;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    // position: sticky;
    // left: 0;
}

.chart-container {
    position: relative;
    display: flex;
    border-radius: 50%;
    background: white;
    width: 268px;
    height: 268px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;

}

.btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.deposit {
    background: #21A315;
    border-radius: 8px;
    width: 125px;
    height: 40px;
    color: white;
}

.withdraw {
    background: #CF0F0F;
    border-radius: 8px;
    border-radius: 8px;
    width: 125px;
    height: 40px;
    color: white;
}

.wealth-title {
    font-size: 14px;
    color: #4F4F4F;
    cursor: pointer;
}

.active-title {
    color: #D09C0A;
}

.wealth-coin {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 10px 0;

    span {
        font-size: 14px;
    }
}

@media only screen and (max-width: 900px) {
    .wallet-container {
        flex-direction: column-reverse;
    }
}

@media only screen and (max-width:700px) {

    .ENname,
    .list-img,
    .balance,
    .value,
    .withdrawal,
    .deal {
        display: none;
    }
}
@media only screen and (max-width:400px) {
    .chart-container{
        width: 200px;
        height: 200px;
    }
}
</style>
